import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1034.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
	<path d="M4950 10049 c-540 -25 -1025 -131 -1550 -338 -122 -49 -133 -54 -305
-137 -554 -267 -1054 -646 -1458 -1104 -56 -63 -105 -117 -108 -120 -20 -17
-240 -315 -306 -415 -607 -916 -878 -2013 -768 -3105 87 -858 417 -1700 934
-2380 185 -244 465 -543 667 -715 59 -49 112 -96 120 -103 39 -38 358 -268
459 -331 609 -382 1263 -613 2005 -707 172 -22 684 -30 887 -15 257 20 566 68
778 123 239 61 588 180 764 260 380 174 634 321 928 539 73 54 145 109 160
122 303 261 499 458 702 702 88 106 239 319 333 470 309 496 525 1046 638
1630 88 453 105 1026 44 1493 -99 762 -393 1516 -836 2138 -84 118 -234 309
-302 384 -128 141 -352 364 -431 431 -22 19 -42 36 -45 39 -22 22 -145 121
-235 190 -433 329 -950 591 -1490 755 -495 151 -1055 219 -1585 194z m605 -49
c602 -52 1191 -216 1715 -480 90 -45 247 -131 301 -165 9 -5 34 -20 55 -33 84
-48 272 -178 404 -277 260 -196 578 -504 770 -744 19 -24 47 -58 61 -75 193
-233 429 -619 579 -946 303 -662 444 -1343 427 -2068 -12 -523 -86 -942 -252
-1431 -444 -1304 -1450 -2352 -2740 -2855 -104 -41 -275 -98 -430 -145 -174
-52 -491 -115 -745 -147 -168 -21 -716 -30 -893 -15 -336 30 -665 89 -917 165
-206 63 -279 87 -445 153 -500 197 -938 462 -1352 818 -127 109 -406 391 -490
494 -16 19 -54 66 -86 105 -129 155 -301 405 -416 606 -578 1006 -762 2161
-530 3310 104 516 312 1035 614 1534 61 101 284 413 330 462 5 6 28 33 50 60
171 207 423 457 640 633 11 9 34 27 51 41 17 14 44 35 60 47 398 298 743 490
1179 658 389 150 853 256 1285 294 139 12 636 12 775 1z"/>
<path d="M4423 7714 c-3 -8 -2 -19 3 -24 6 -6 244 -11 549 -12 394 -3 556 -7
600 -16 248 -54 441 -168 580 -342 28 -36 55 -72 59 -80 11 -23 31 -56 42 -71
16 -20 50 -86 74 -144 12 -27 27 -63 35 -80 7 -16 23 -64 35 -105 12 -41 28
-95 35 -120 19 -62 34 -142 46 -230 5 -40 14 -92 20 -113 6 -21 14 -101 19
-178 5 -76 14 -182 20 -236 13 -117 9 -490 -8 -688 -19 -215 -55 -485 -67
-505 -4 -6 -11 -35 -15 -63 -15 -89 -56 -224 -91 -303 -11 -23 -19 -44 -19
-47 0 -7 -76 -163 -93 -192 -50 -83 -80 -120 -192 -230 -53 -53 -114 -102
-145 -118 -30 -16 -62 -32 -70 -37 -36 -22 -111 -50 -132 -50 -13 0 -29 -5
-35 -11 -19 -19 -225 -43 -368 -43 -195 -1 -276 21 -337 89 -30 33 -65 117
-72 170 -4 28 -11 75 -16 105 -5 30 -9 222 -9 427 l-1 372 -25 23 c-66 62
-174 135 -237 159 -16 6 -28 15 -28 19 0 5 -10 12 -22 15 -11 4 -43 20 -70 36
-27 16 -54 29 -60 29 -6 0 -29 9 -52 19 -41 19 -123 55 -161 71 -51 21 -126
54 -184 82 -47 22 -66 27 -75 18 -8 -8 -13 -189 -17 -643 -3 -369 -10 -663
-17 -707 -10 -68 -37 -135 -77 -193 -23 -32 -75 -58 -168 -82 -99 -26 -102
-28 -94 -50 6 -13 125 -15 1112 -15 951 0 1120 2 1208 16 156 23 317 57 337
69 8 5 30 12 49 16 88 18 289 106 413 178 216 128 467 370 584 564 16 27 37
61 46 75 28 45 126 244 143 292 21 59 41 124 55 180 6 25 16 59 22 75 5 17 16
66 24 110 8 44 19 107 25 140 15 79 15 590 1 675 -6 36 -16 97 -22 135 -6 39
-16 84 -22 100 -5 17 -14 49 -19 73 -13 62 -54 188 -84 257 -69 157 -168 337
-211 385 -8 8 -33 40 -56 70 -104 137 -316 329 -443 401 -107 61 -141 79 -155
85 -8 4 -46 21 -85 39 -73 35 -93 42 -215 80 -116 36 -230 61 -385 85 -33 5
-379 10 -768 10 -633 0 -708 -2 -714 -16z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
